/**
 * @module button
 * @description Collection of functions to add some additional functionality for the buttons:
 * - bindInteractiveButtons: Add 'click' event listener to toggle (hide/show) another element.
 * - addSpinner: Add spinner
 * - removeSpinner: Remove spinner
 */

import {initToggleContent} from './toggleContent.js';

/**
 * Add spinner to the given button
 *
 * @param {Event} clickEvent
 */
function spinOnClicked(clickEvent) {
    const button = clickEvent.target.closest('.button');
    button.classList.add('button--loading');
}

export class Button {
    static bindInteractiveButtons() {
        initToggleContent();

        const spinnerButtons = [...document.querySelectorAll('.button.ixdf-spinner:not(input)')];
        spinnerButtons.forEach((button) => button.addEventListener('click', spinOnClicked));

        const expandListButtons = [...document.querySelectorAll('[data-expand-list]')];
        expandListButtons.forEach((button) => {
            button.addEventListener('click', (event) => {
                event.preventDefault();
                const id = button.getAttribute('data-expand-list');
                const target = document.getElementById(id);
                if (button.matches('.expanded')) {
                    button.classList.remove('expanded');
                    target.classList.remove('expanded');
                } else {
                    button.classList.add('expanded');
                    target.classList.add('expanded');
                }
            });
        });
    }

    /**
     * Add spinner to the given button(s).
     * Use 'removeSpinner' method to remove this spinner.
     * @param {HTMLElement} button
     */
    static addSpinner(button) {
        button.classList.add('button--loading');
    }

    /**
     * Remove spinner from the button, added by 'addSpinner' method.
     * @param {HTMLElement} button
     */
    static removeSpinner(button) {
        button.classList.remove('button--loading');
    }
}

window.addEventListener('pageshow', function () {
    const loadingButtons = document.querySelectorAll('.button--loading');
    loadingButtons.forEach((button) => {
        button.classList.remove('button--loading');
    });
});
